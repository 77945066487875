<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <b-card
            no-body
            class="mb-0"
          >

            <div class="m-2">

              <!-- Table Top -->
              <b-row>

                <!-- Per Page -->
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <div>
                    <b-form-group
                      label="Exibir"
                      label-for="idSearchBox"
                      class="mb-0"
                    >
                      <v-select
                        id="idSearchBox"
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block"
                      >
                        <span slot="no-options">Não há opções para essa quantidade.</span>
                      </v-select>
                    </b-form-group>
                  </div>
                  <b-form-input
                    v-model.lazy="searchQuery"
                    placeholder="Buscar"
                    type="text"
                    class="d-inline-block mt-2 ml-1"
                  />
                </b-col>
              </b-row>
            </div>

            <b-table
              class="position-relative"
              primary-key="id"
              responsive
              show-empty
              striped
              hover
              empty-text="Não há permissões cadastradas."
              :items="permissions"
              :fields="tableColumns"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              @row-clicked="onRowSelected"
            >
              <!-- Column: Name -->
              <template #cell(field_name)="data">
                <b-media vertical-align="center">
                  <b-link
                    :to="{ name: 'permission-view', params: { id: data.item.id } } "
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ data.item.name }}
                  </b-link>
                </b-media>
              </template>

              <!-- Column: Description -->
              <template #cell(field_description)="data">
                <div
                  class="text-wrap"
                >
                  {{ data.item.description }}
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(field_actions)="data">
                <div
                  :class="totalItems > 1 ? '' : 'dropdownfix'"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    :dropup="true"
                    :boundary="'scrollParent'"
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      :to=" { name: 'permission-view', params: { id: data.item.id } } "
                    >
                      <feather-icon icon="FileTextIcon" />
                      <span class="align-middle ml-50">Detalhes</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="!hasPermissionToUpdate"
                      :to=" { name: 'permission-edit', params: { id: data.item.id } } "
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Editar</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="!hasPermissionToDelete"
                      @click="showConfirmOrCancelDelete(data.item)"
                    >
                      <svg-icon
                        type="mdi"
                        size="16"
                        :path="mdiTrashCanOutline"
                      />
                      <span class="align-middle ml-50">Remover</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalItems"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <div>
        <b-button
          id="addPermissionBtn"
          v-b-tooltip.hover
          class="btn-icon rounded-circle m-3"
          variant="primary"
          size="md"
          :to="{ name: 'permission-create'}"
          :disabled="!hasPermissionToWrite"
          title="Adicionar Permissões"
        >
          <svg-icon
            type="mdi"
            :path="mdiPlus"
          />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'
import SvgIcon from '@jamescoyle/vue-icon'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import router from '@/router'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    SvgIcon,
    vSelect,
    SpinLoader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    const perPage = 10
    const totalItems = 0
    const currentPage = 1
    const searchQuery = ''
    const sortBy = 'name'
    const isSortDirDesc = false
    const perPageOptions = [10, 25, 50, 100]

    const tableColumns = [
      {
        key: 'field_name',
        label: 'Permissão',
        sortable: true,
        thStyle: {
          'min-width': '10rem',
        },
      },
      {
        key: 'field_description',
        label: 'Descricão',
        thStyle: {
          'min-width': '20rem',
        },
      },
      {
        key: 'field_actions',
        label: 'Ações',
      },
    ]

    const permissions = []

    const isLoaded = false
    return {
      perPage,
      totalItems,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      perPageOptions,
      tableColumns,
      permissions,
      isLoaded,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.permission.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.permission.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.permission.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.permission.can_delete
    },
  },
  watch: {
    perPage: 'fetchData',
    currentPage: 'fetchData',
    searchQuery: function updateSearchQuery(value) {
      this.currentPage = 1
      this.searchQuery = value
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      syslic
        .permission
        .fetchProfilePermissionList({
          p: this.currentPage,
          page_size: this.perPage,
          q: this.searchQuery,
        })
        .then(response => {
          this.totalItems = response.data.count
          this.permissions = response.data.results
          this.isLoaded = true
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados do perfil de permissões.',
              text: 'Não foi possível ler os dados do perfil de permissões, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.isLoaded = true
        })
    },
    deleteProfilePermission(item) {
      syslic
        .permission
        .deleteProfilePermission(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Perfil de permissão removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.permissions.splice(this.permissions.indexOf(item), 1)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover.',
              text: 'Não foi possível remover o perfil de permissãodo servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover este perfil de permissões.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteProfilePermission(item)
          }
        })
    },
    onRowSelected(item) {
      router.push({
        name: 'permission-view',
        params: {
          id: item.id,
        },
      })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPlus,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#addPermissionBtn {
  position: fixed;
  right: 0;
  bottom: 0;
}

#addPermissionBtn img{
  width: 2.0rem;
  height: 2.0rem;
  filter: invert(100) contrast(100) brightness(1);
}
</style>
